@import "./config";

.dashboard {
    margin: 20px 0px;

    .dashboard-items-void {
        border-radius: 08px;
        margin: 05px 10px;
        display: flex;
        background-color: $danger-color;
        color: $secondary-color;

        .icon {
            height: 50px;
            width: 40px;
            transition: transform .2s;

            &:hover {
                transform: scale(1.5);
            }
        }

        .padding-dashboard-items {
            padding-left: 7px;

            label {
                font-size: 24px;
                font-weight: 400;
            }

            span {
                padding-left: 10px;
                font-size: 20px;
                font-weight: 700;
            }
        }
    }

    .dashboard-items {
        border-radius: 08px;
        margin: 05px 10px;
        display: flex;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .icon {
            height: 50px;
            width: 40px;
            transition: transform .2s;

            &:hover {
                transform: scale(1.5);
            }
        }

        .padding-dashboard-items {
            padding-left: 7px;

            label {
                font-size: 24px;
                font-weight: 400;
            }

            span {
                padding-left: 10px;
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
}

.void {
    // color: $primary-color;
    color: $secondary-color;
    background-color: #ee4949;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
}

.completed {
    color: $secondary-color;
    background-color: rgb(84, 73, 238);
    border-radius: 10px;
    height: 35px;
    width: auto;
    padding: 5px;
    text-align: center;
}