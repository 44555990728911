/* Modal Overlay */
.custom-modal-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    padding: 50px 0;
}

/* Modal Dialog */
.custom-modal-dialog {
    position: relative;
    margin: auto;
    max-width: 600px;
    height: 100%;
    overflow: hidden;
}

/* Modal Content */
.custom-modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
}

/* Modal Header */
.custom-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    position: relative;
}

/* Close Button (Cross) */
.custom-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.35rem;
    color: #333;
    cursor: pointer;
}

.custom-close-btn:hover {
    color: #ff0000;
}

/* Modal Body */
.custom-modal-body {
    padding: 20px;
    max-height: calc(100% - 120px);
    /* Prevents the body from being taller than the modal */
    overflow-y: auto;
}

.custom-order-summary {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.custom-col {
    width: 100%;
}

.custom-details-list {
    list-style-type: none;
    padding: 0;
}

.custom-details-list li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 0.9rem;
}

.custom-item-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.custom-item-table th,
.custom-item-table td {
    padding: 5px;
    text-align: left;
    border: 1px solid #ddd;
    font-size: 0.875rem;
}

.custom-item-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.custom-item-table td {
    background-color: #fff;
}

/* Charges (Employee, Service Charge, VAT) */
.custom-charges {
    margin-top: 20px;
}

.custom-charges-list {
    list-style-type: none;
    padding: 0;
}

.custom-charges-list li {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
}

/* Modal Footer */
.custom-modal-footer {
    padding: 10px 20px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-footer-content {
    font-size: 1.0125rem;
}

.custom-footer-close-btn {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.custom-footer-close-btn:hover {
    background-color: #e0e0e0;
}

.customer-table-title {
    padding: 10px 0;
}