/* Optional: Import config if necessary */
@import "./config";

/* Style for rows where completedAt === "VOID" */
.void-row {
    background-color: #9F000F;  /* Red background */
    color: white;               /* White text */
}

/* Style for table cells */
.table-cell {
    padding: 10px 15px;
    text-align: center;
    font-size: 17px;
    border-bottom: 2px solid gray;
}

/* Optional: Style for the Date column (table header for Date) */
.table-date {
    font-weight: bold;
}

/* Optional: Style for empty cells (for alignment purposes) */
.empty-cell {
    padding: 10px 25px;
    text-align: center;
}

/* Style for table headers */
.table-header {
    padding: 10px 15px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    border-bottom: 2px solid gray;
}

/* General style for the table */
table {
    width: 100%;
    border-collapse: collapse;
}
