@import "./config";

.btn-search-view {
    display: flex;
    justify-content: center;
    margin: 20px 0px;

    .btn-search {
        border: 1px solid $btn-search;
        background-color: $btn-search;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;

        &:hover {
            background-color: $btn-search-hover;
        }
    }
}