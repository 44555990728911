@import "./config";
/* Ensure the table container has a fixed height with overflow enabled */
.table-responsive {
  max-height: 400px; /* Adjust based on your needs */
  overflow-y: auto; /* This makes the table scrollable */
  position: relative; /* Add relative positioning to the container */
  //   background-color: white;
  //   opacity: 1;
}
.table-header-bg {
  background-color: white;
}
/* Make the table header sticky at the top */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
// .sticky-header th {
//     position: sticky;
//     top: 0;
//     background-color: white;
//     z-index: 2;
//   }

.sticky-footer td {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: -1; /* Lower z-index to ensure it stays below the header */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for better visibility */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white !important;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 1100px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-height: 90vh; /* Increased height */
  overflow-y: auto;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-header {
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}

.modal-group,
.modal-date-range {
  display: block;
  font-size: 1rem; 
  margin: 5px 0;
  text-align: center;
}

.modal-group strong,
.modal-date-range strong {
  font-weight: bold;
}

.modal-table-container {
  max-height: 60vh;
  overflow-y: auto;
}

.modal-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.modal-table th, .modal-table td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

.modal-table th {
  background: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;
}

.modal-table td {
  font-size: 0.9rem;
}

.modal-table td,
.modal-table th {
  font-size: 0.9rem;
}

.modal-content tfoot {
  background-color: #f9f9f9; /* Light grey background for subtle highlight */
  font-weight: bold; /* Optional: Makes the text bold */
  color: #333; /* Dark color for the text to stand out */
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.modal-content tfoot td {
  padding: 8px; /* Ensures that there is spacing in the footer cells */
}

