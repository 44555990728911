@import "../config";

.handle-date {
    width: 80px !important;
}

.search-btn-div {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100%;
}

.btn-search-style .btn-show {
    margin-top: 10px;
}

.handle-date-input-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.date-picker-style,
.btn-search-style,
.input-customer-name {
    flex: 1 1 250px;
    min-width: 200px;
}

@media (max-width: 768px) {
    .handle-date-input-btn {
        flex-direction: column;
        align-items: flex-start;
    }

    .date-picker-style,
    .btn-search-style,
    .input-customer-name {
        width: 100%;
    }
}

.table-customer-history,
.table-complementary-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

h6 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.table-history-responsive {
    width: 100%;
    overflow-x: auto;
    max-height: 500px;
    position: relative;
    margin: 0 auto;
}

.table-data-customer {
    max-width: 1200px;
}

.table-data-complimentary {
    max-width: 800px;
}

.table-customer,
.table-complementary {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
}

.table-customer th,
.table-complementary th,
.table-customer td,
.table-complementary td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
}

.table-customer th,
.table-complementary th {
    font-size: 1rem;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 10;
}

.table-customer td,
.table-complementary td {
    font-size: 0.9rem;
    color: #555;
}

.table-complementary tfoot td,
.table-customer tfoot td {
    font-weight: bold;
}

.table-complementary tfoot td:last-child,
.table-customer tfoot td:last-child {
    color: #5bc0de;
}

table tr:last-child td span {
    color: #d9534f;
}

.table-customer tr:hover,
.table-complementary tr:hover {
    background-color: #f9f9f9;
}

.table-customer tfoot,
.table-complementary tfoot {
    background-color: #f4f4f4;
    font-weight: bold;
    border-top: 1px solid #e1e1e1;
    position: sticky;
    bottom: 0;
    /* Sticky footer stays at the bottom */
    z-index: 1;
}

/* Responsive Design */
@media (max-width: 768px) {

    .table-customer th,
    .table-customer td,
    .table-complementary th,
    .table-complementary td {
        padding: 8px;
        font-size: 0.85rem;
    }

    .table-customer,
    .table-complementary {
        width: 100%;
    }
}

.table-complementary th {
    background-color: #CE84B9;
    color: $secondary-color;
}

.table-customer th {
    background-color: $btn-seen;
    color: $secondary-color;
}

.btn-show {
    width: 100px;
    height: 40px;
    border: 1px solid $btn-search;
    background-color: $btn-search;
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin-right: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: $btn-search-hover;
    }
}

.bg-history {
    h5 {
        text-align: center;
    }
}

.error-message {
    color: red;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}

.no-data-message {
    color: orange;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}